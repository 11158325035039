.d-none{
  display: none !important;
}
.btn{
  @apply relative py-4 px-6 rounded inline-flex flex-row items-center justify-center font-medium cursor-pointer transition duration-150 ease-in-out bg-gray-200 text-gray-700;
  &:focus{
    @apply outline-none;
  }
  &:hover{
    @apply bg-gray-300;
  }
  &.btn-sm{
    @apply py-2 px-3 leading-5;
  }
  &.btn-primary{
    @apply bg-indigo-800 text-white;
    &:hover{
      @apply bg-indigo-900;
    }
  }
  &.btn-success{
    @apply bg-green-500 text-white;
    &:hover{
      @apply bg-green-600;
    }
  }
  &.btn-danger{
    @apply bg-red-600 text-white;
    &:hover{
      @apply bg-red-700;
    }
  }
  &.btn-block{
    @apply block w-full text-center;
  }
  &.text-transparent{
    @apply text-transparent;
  }
  &:disabled, &[disabled]{
    @apply pointer-events-none opacity-25 cursor-not-allowed filter-grayscale striped-bg-white;
  }
}

.collapse{
  &:not(.show){
    @apply hidden;
  }
}
.collapsing{
  @apply relative h-0 overflow-hidden transition duration-150 transition-height;
}

.nav-tabs{
  .active {
    @apply flex relative border-b-2 pt-px border-transparent border-green-500;

    &:hover {
      @apply border-gray-300 bg-white border-green-500;
    }
  }
}

.dropdown-menu{
  @apply bg-white border shadow-lg z-50 transform origin-t left-0 min-w-0;

  @media(min-width: theme('screens.sm')) {
    @apply absolute left-auto right-auto transform origin-tr rounded-b;
  }
}


div.task-notice img.notice-avatar{
  width: 30px;
  top: -1px;
}
.position-absolute{
  @apply absolute;
}
.rounded-circle{
  @apply rounded-full;
}

.dash-inbox .row{
  @apply -mx-2 flex flex-wrap;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12{
  @apply relative w-full pl-4 pr-4;
}
.col{
  @apply flex-grow max-w-full;
  flex-basis: 0;
}
.col-1{
  max-width: 8.333333%;
  flex: 0 0 8.333333%;
}
.col-2{
  max-width: 16.666667%;
  flex: 0 0 16.666667%;
}
.col-3{
  max-width: 25%;
  flex: 0 0 25%;
}
.col-4{
  max-width: 33.333333%;
  flex: 0 0 33.333333%;
}
.col-5{
  max-width: 41.666667%;
  flex: 0 0 41.666667%;
}
.col-6{
  max-width: 50%;
  flex: 0 0 50%;
}
.col-7{
  max-width: 58.333333%;
  flex: 0 0 58.333333%;
}
.col-8{
  max-width: 66.666667%;
  flex: 0 0 66.666667%;
}
.col-9{
  max-width: 75%;
  flex: 0 0 75%;
}
.col-10{
  max-width: 83.333333%;
  flex: 0 0 83.333333%;
}
.col-11{
  max-width: 91.666667%;
  flex: 0 0 91.666667%;
}
.col-12{
  max-width: 100%;
  flex: 0 0 100%;
}

// bootstrap color palette
:root {
  --blue: theme('colors.blue.500');
  --indigo: theme('colors.indigo.500');
  --purple: theme('colors.purple.500');
  --pink: theme('colors.pink.500');
  --red: theme('colors.red.600');
  --orange: theme('colors.orange.600');
  --yellow: theme('colors.yellow.600');
  --green: theme('colors.green.600');
  --teal: theme('colors.teal.600');
  --cyan: theme('colors.teal.400');
  --white: theme('colors.white');
  --gray: theme('colors.gray.300');
  --grey: theme('colors.gray.300');
  --grey-dark: theme('colors.gray.700');
  --gray-dark: theme('colors.gray.700');
  --blue-grey: theme('colors.gray.600');
  --blue-gray: theme('colors.gray.600');
  --primary: theme('colors.indigo.800');
  --secondary: theme('colors.gray.700');
  --success: theme('colors.green.500');
  --info: theme('colors.blue.300');
  --warning: theme('colors.orange.500');
  --danger: theme('colors.red.500');
  --light: theme('colors.gray.100');
  --dark: theme('colors.gray.900');
}
