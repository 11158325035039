.tippy-box[data-theme~='lotusmini']{
  @apply text-black bg-transparent;
  .tippy-content{
    @apply p-0;
  }
  .tippy-arrow::before{
    z-index: 20;
  }
  &[data-placement^='top'] {
    > .tippy-arrow::before {
      border-top-color: #fff;
    }
  }
  &[data-placement^='bottom'] {
    > .tippy-arrow::before {
      border-bottom-color: #fff;
    }
  }
  &[data-placement^='left'] {
    > .tippy-arrow::before {
      border-left-color: #fff;
    }
  }
  &[data-placement^='right'] {
    > .tippy-arrow::before {
      border-right-color: #fff;
    }
  }
}
