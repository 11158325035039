.tribute-container{
  @apply mt-1 bg-white rounded shadow z-20 py-2 max-h-64 overflow-x-auto;
  ul{
    @apply flex flex-col;
  }
  li{
    @apply py-1 px-4 flex flex-wrap items-center relative cursor-pointer;
    small{
      span{
        @apply text-gray-600;
      }
    }
    &:hover, &.highlight{
      @apply bg-green-500 text-white;
      small{
        @apply text-green-300;
        span{
          @apply text-white;
        }
      }
    }
  }
}
