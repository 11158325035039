.table-head-shadow{
	background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
	left: -2px;
	right: -2px;
	bottom: -4px;
}

.table.vuetable{
	@apply w-full border-t mt-4;
	th{
		@apply text-left font-medium border-b py-1 bg-gray-100;
		&.sortable{
			&:hover{
				@apply text-gray-800;
			}
		}
	}
	.sort-icon{
		&.sort{
			.sort-up, .sort-down{
				@apply invisible;
			}
		}
		&.show-sort-up{
			.sort, .sort-down{
				@apply invisible;
			}
		}
		&.show-sort-down{
			.sort, .sort-up{
				@apply invisible;
			}
		}
	}
	td{
		@apply border-b py-1;
	}
	.vuetable-th-component-checkbox, .vuetable-td-component-checkbox{
		@apply pl-8;
	}
}
