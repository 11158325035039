// @tailwind core
@tailwind base;
@tailwind components;

body{
  @apply text-base;
}

// @project components
@import "components/richtext";
@import "components/misc";
@import "components/forms";
@import "components/table";
@import "components/tippy";
@import "components/tribute";
@import "components/bootstrap";

// @tailwind utils
@tailwind utilities;
