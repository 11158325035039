.lotus-richtext {
  @apply leading-normal;
  a {
    @apply underline text-green-500;
    &:hover {
      @apply no-underline;
    }
  }
  abbr {
    @apply border-b border-dotted;
    cursor: help;
  }
  cite {
    @apply italic;
  }
  hr {
    @apply bg-gray-300 border-none block h-px my-1;
  }
  img {
    vertical-align: text-bottom;
  }
  ins {
    @apply bg-green-300 no-underline;
  }
  mark {
    @apply bg-yellow-500;
  }
  small {
    @apply text-sm;
  }
  strong {
    @apply font-bold;
  }
  sub,
  sup {
    @apply text-xs;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }
  p,
  dl,
  ol,
  ul,
  blockquote,
  pre,
  table {
    @apply mb-1;
  }
  p:last-child,
  dl:last-child,
  ol:last-child,
  ul:last-child,
  blockquote:last-child,
  pre:last-child,
  table:last-child {
    @apply mb-0;
  }
  p:empty {
    @apply hidden;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-medium leading-tight;
  }
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    @apply mt-0;
  }
  h1 {
    @apply text-3xl leading-none;
  }
  h2 {
    @apply text-xl leading-tight font-bold tracking-tight;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-lg text-gray-800;
  }
  h5 {
    @apply text-base;
  }
  h6 {
    @apply text-base;
  }
  dd {
    @apply ml-6;
  }
  ol,
  ul {
    @apply ml-6;
    list-style-position: outside;
  }
  ol {
    list-style-type: decimal;
  }
  ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol {
    list-style-type: lower-roman;
  }
  ol ol ol ol {
    list-style-type: lower-greek;
  }
  ol ol ol ol ol {
    list-style-type: decimal;
  }
  ol ol ol ol ol ol {
    list-style-type: lower-alpha;
  }
  ul {
    list-style-type: disc;
  }
  ul ul {
    list-style-type: circle;
  }
  ul ul ul {
    list-style-type: square;
  }
  ul ul ul ul {
    list-style-type: circle;
  }
  ul ul ul ul ul {
    list-style-type: disc;
  }
  ul ul ul ul ul ul {
    list-style-type: circle;
  }
  blockquote {
    @apply border-0 border-l-4 py-2 px-5;
  }
  blockquote p {
    @apply mb-0;
  }
  code,
  kbd,
  samp,
  pre {
    @apply bg-gray-100 text-gray-800;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
  }
  code,
  kbd,
  samp {
    @apply rounded leading-relaxed pt-1 px-2 pb-1 align-baseline;
  }
  pre {
    @apply overflow-auto py-4 px-6;
  }
  pre code {
    @apply bg-transparent text-base leading-none p-0;
  }
  figure {
    @apply mb-10 text-center;
  }
  figure img{
    @apply mx-auto;
  }
  figure:first-child {
    @apply mt-0;
  }
  figure:last-child {
    @apply mb-0;
  }
  figcaption {
    @apply text-sm mt-3;
  }
  table {
    @apply w-full;
  }
  table pre {
    @apply whitespace-pre-wrap;
  }
  th,
  td {
    @apply text-base p-3 border leading-normal;
  }
  thead tr,
  tfoot tr {
    @apply bg-gray-100;
  }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    @apply p-3;
  }
  thead th code,
  thead td code,
  tfoot th code,
  tfoot td code {
    @apply bg-white;
  }
  tbody tr {
    @apply bg-white;
  }
  &.lotus-richtext-preformated{
    hr{
      @apply my-4;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-2;
    }
    p,
    dl,
    ol,
    ul,
    blockquote,
    pre,
    table {
      @apply mb-4;
    }
    p:last-child,
    dl:last-child,
    ol:last-child,
    ul:last-child,
    blockquote:last-child,
    pre:last-child,
    table:last-child {
      @apply mb-0;
    }
    blockquote p:last-child{
      @apply mb-0;
    }
  }
  // alerts
  .bg-gray-200 &{
    a {
      @apply underline text-gray-800;
    }
    hr{
      @apply bg-gray-300;
    }
  }
  .bg-blue-200 &{
    a {
      @apply underline text-blue-800;
    }
    hr{
      @apply bg-blue-300;
    }
  }
  .bg-green-200 &{
    a {
      @apply underline text-green-800;
    }
    hr{
      @apply bg-green-300;
    }
  }
  .bg-yellow-200 &{
    a {
      @apply underline text-yellow-800;
    }
    hr{
      @apply bg-yellow-300;
    }
  }
  .bg-red-200 &{
    a {
      @apply underline text-red-800;
    }
    hr{
      @apply bg-red-300;
    }
  }
  .bg-red-200 &{
    a {
      @apply underline text-red-800;
    }
    hr{
      @apply bg-red-300;
    }
  }
  .bg-gray-200 &, .bg-blue-200 &, .bg-green-200 &, .bg-yellow-200 &, .bg-red-200 &, .bg-red-200 &{
    a{
      &:hover {
        @apply no-underline;
      }
    }
  }
}
