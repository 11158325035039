.aspect-ratio-square, .aspect-ratio-1by1 {
	padding-top: 100%;
}
.aspect-ratio-16\/9, .aspect-ratio-16by9 {
	padding-top: 56.25%;
}
.aspect-ratio-4\/3, .aspect-ratio-4by3 {
	padding-top: 75%;
}
.aspect-ratio-21\/9, .aspect-ratio-21by9 {
	padding-top: 42.86%;
}

.loader,
.loader:after {
	border-radius: 9999px;
}
.loader {
	@apply relative border-solid;
	text-indent: -9999em;
	border-color: currentColor;
	border-right-color: theme('colors.semitransparentblack.100');
	border-top-color: theme('colors.semitransparentblack.100');
	-webkit-animation: load 750ms infinite linear;
	animation: load 750ms infinite linear;
}
@-webkit-keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.striped-bg-white{
	background-image: repeating-linear-gradient(-45deg, transparent, transparent 10px, rgba(255, 255, 255, 0.2) 10px, rgba(255, 255, 255, 0.2) 20px );
}
.bg-white-to-transparent-h{
	background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}
.bg-white-to-transparent-v{
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}
.bg-yellow-200-to-transparent-h{
	background: linear-gradient(to right, rgba(254,252,191,1) 0%,rgba(254,252,191,0) 100%);
}
.bg-yellow-200-to-transparent-v{
	background: linear-gradient(to bottom, rgba(254,252,191,1) 0%,rgba(255,252,191,0) 100%);
}
.custom-file-input:lang(en)~.custom-file-label:after {
	@apply relative py-2 px-6 rounded inline-flex flex-row items-center justify-center font-medium cursor-pointer transition-all bg-gray-200 text-gray-700 ml-2 h-10;
	content:"Browse"
}

.custom-file-input~.custom-file-label[data-browse]:after {
	content:attr(data-browse)
}

.form-input, .form-textarea{
	&.focus-alert-next-button{
		+ .btn .focused{
			@apply opacity-0;
		}
		&:focus:not(:placeholder-shown) + .btn{
			@apply bg-green-500 text-white;
			.focused{
				@apply opacity-100
			}
		}
	}
}

.close-trigger-icon{
	@apply hidden;
}
.opened{
	> .open-trigger-icon{
		@apply hidden;
	}
	> .close-trigger-icon{
		@apply block;
	}
}

.clock {
	@apply relative w-6 h-6 block bg-green-500 rounded-full;

	&__center {
		@apply absolute left-1/2 top-1/2 block h-1 w-1 bg-white rounded-full;
		transform:translateX(-50%) translateY(-50%);
	}

	&__hand {
		@apply absolute left-1/2 top-1/2 block h-1/2 bg-white rounded-sm;
		width: 2px;
		transform-origin:top center;

		&--minute {
			transform:translateX(-50%) rotate(-45deg);
			animation: spin-minute-hand 10s forwards ease-in-out;
		}

		&--hour {
			@apply h-1/3;
			transform:translateX(-50%) rotate(120deg);
			animation: spin-hour-hand 10s forwards ease-in-out;
		}
	}

}

@keyframes spin-minute-hand {
	0%	 {transform:translateX(-50%) rotate(-45deg);}
	30%  {transform:translateX(-50%) rotate(-45deg);}
	100% {transform:translateX(-50%) rotate(675deg);}
}

@keyframes spin-hour-hand {
	0%   {transform:translateX(-50%) rotate(120deg);}
	20%  {transform:translateX(-50%) rotate(120deg);}
	100% {transform:translateX(-50%) rotate(180deg);}
}

/* alpine js - this is useful for hiding pre-initialized DOM */
[x-cloak] {
	display: none;
}

.panel{
	&:hover{
		.help-icon{
			@apply opacity-100 scale-100;
		}
	}
}
