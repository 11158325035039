input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	transition: background-color 5000s ease-in-out 0s;
	filter: none;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
	background: white;
	border: 1px solid green;
	-webkit-text-fill-color: green;
	-webkit-box-shadow: 0 0 0px 1000px #000 inset;
	transition: background-color 5000s ease-in-out 0s;
	filter: none;
}
input[type=number].appearance-none {
	-moz-appearance: textfield;
}
.form-checkbox.dark{
    &:checked{
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='1a202c' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e")
    }
}
body{
	.flatpickr-calendar{
		@apply shadow-xl;
		&.arrowTop {
			&:after, &:before{
				@apply hidden;
			}
		}
		&.arrowBottom{
			&:after, &:before{
				@apply hidden;
			}
		}
	}
	.flatpickr-months{
		@apply bg-gray-100;
	}
	.flatpickr-weekdays{
		@apply bg-gray-100;
	}
	.flatpickr-current-month{
		.flatpickr-monthDropdown-months{
			@apply text-base;
		}
		input.cur-year{
			@apply text-base;
		}
	}
	.flatpickr-day.today:hover, .flatpickr-day.today:focus{
		@apply border-green-500 bg-green-500;
	}
	span.flatpickr-day.today:not(.selected):hover, span.flatpickr-day.prevMonthDay.today:not(.selected):hover, span.flatpickr-day.nextMonthDay.today:not(.selected):hover{
		@apply border border-green-500;
	}
	span.flatpickr-day.today:not(.selected), span.flatpickr-day.prevMonthDay.today:not(.selected), span.flatpickr-day.nextMonthDay.today:not(.selected){
		border-bottom-color: theme('colors.green.500');
	}
	.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
		@apply bg-green-600;
		border-color: theme('colors.green.600');
	}
	.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover, .flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover{
		@apply text-green-500;
	}
	.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg{
		@apply fill-current;
	}

	// TODO: move me to AbstractDateTimeControl.php after bootstrap is death
	.bs-flatpickr-input{
		@apply flex-1 pl-3 py-2 rounded min-w-0;
		&:focus{
			@apply outline-none;
		}
	}
	.bs-flatpickr-wrapper{
		@apply flex w-full form-input p-0;
	}
	.bs-flatpickr-triggers{
		@apply flex flex-grow-0 items-stretch;
	}
	.bs-flatpickr-calendar-trigger{
		@apply flex flex-row items-center text-gray-500 cursor-pointer pr-2 bg-transparent appearance-none;
		&:hover{
			@apply text-gray-500;
		}
		&:focus{
			@apply outline-none;
		}
	}
	.bs-flatpickr-delete-trigger{
		@apply flex flex-row items-center text-red-500 cursor-pointer pr-2 bg-transparent appearance-none;
		&:hover{
			@apply text-red-600;
		}
		&:focus{
			@apply outline-none;
		}
	}
}
.btn-utils{
	&.text-transparent{
		@apply text-transparent;
	}
	&:disabled, &[disabled]{
	@apply pointer-events-none opacity-25 cursor-not-allowed filter-grayscale striped-bg-white;
	}
}
